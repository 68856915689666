import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layouts/Layout"
import {
  InteriorBanner,
  ContactSection,
  ImageGrid,
} from "../components/elements"
import { SEO, Section, Col, Flex, Type, Color } from "../components/utilities"

import banner from "../images/pool2.jpg"
import pic1 from "../images/girl.jpg"
import pic2 from "../images/drinks.jpg"
import pic3 from "../images/boy.jpg"
import chad from "../images/chad.png"

const imageArr = [
  {
    image: pic1,
    altTag: "Young girl playing in a swimming pool with a yellow ball.",
  },
  {
    image: pic3,
    altTag: "Boy swimming underwater in a clear blue pool.",
  },
  {
    image: pic2,
    altTag: "Group of people enjoying drinks by a swimming poolside.",
  },
]

const StyledImage = styled.img`
  width: 450px;
`

const ImageFlex = styled.div`
  padding-left: 2rem;
  padding-right: 2rem;
  flex-grow: 0 !important;
  flex-basis: 450px !important;
  text-align: center;
`

const Caption = styled.div`
  margin-top: 1rem;
  text-align: center;
  font-family: ${Type.header};
  color: ${Color.primary};
  font-size: 1.5rem;
`

const AboutUsPage = () => (
  <Layout>
    <SEO
      title="Northwest Ohio Luxury Pool Contractors"
      description="Hunt Pools are luxury designers and construction contractors with more than 25 years of quality in-ground pool construction experience for Northwest Ohio"
    />
    <InteriorBanner image={banner}>
      <h1>About Us</h1>
      <p>We Are The Experts In Pool Maintenance</p>
    </InteriorBanner>
    <Section>
      <Flex>
        <Col>
          <h2>Experience the experience</h2>
          <p>
            Hunt Pools &amp; Service has 25 plus years of in ground pool
            experience to share with your family. Hunt Pools &amp; Service
            prides ourselves on being responsive, professional and consistent.
            Our experience will make first time pool customers feel comfortable
            throughout the entire process.
            <br />
            <br />
            We have the skills required to be a full service pool building
            company. We offer a complete solution for all your{" "}
            <Link to="/pool-build/" style={{ color: Color.primary }}>
              pool building and design
            </Link>{" "}
            needs.
            <br />
            <br />
            Hunt Pools &amp; Service continues to tailor our business processes
            and communication methods, working with our partners to ensure our
            customers the best quality, experience and value.
            <br />
            <br />
            Once your pool is built with the highest of value you expect, we
            will be here to provide the highest quality{" "}
            <Link to="/pool-service/" style={{ color: Color.primary }}>
              pool services
            </Link>{" "}
            to help maintain your investment. We look forward to the opportunity
            to serve you in the near future.
          </p>
        </Col>
        <ImageFlex>
          <StyledImage
            src={chad}
            alt="Chad Hunt - Hunt Pools and Service Owner"
          />
          <Caption>Chad Hunt, Owner</Caption>
        </ImageFlex>
      </Flex>
    </Section>
    <ImageGrid images={imageArr} />
    <ContactSection />
  </Layout>
)

export default AboutUsPage
